










































































































import { Component, Vue } from 'vue-property-decorator'
import ImgView from '@/components/ImgView/index.vue'

@Component({
  components: {
    ImgView
  }
})
export default class extends Vue {
  loading = false
  homeInfo: any = {}
  imgUrl = ''

  created() {
    if (this.$route.query.id) {
      this.getHouseDetail()
    }
  }

  getHouseDetail() {
    this.loading = true
    this.$api.resident
      .getHouseInfo(this.$route.query.id)
      .then((res: any) => {
        this.loading = false
        if (res.data.success) {
          const info = res.data.data
          this.homeInfo = info
        }
      })
      .catch(() => {
        this.loading = false
      })
  }

  // 跳转居民列表
  gotoPeople(item: any) {
    this.$router.push({
      path: '/residents/people-list',
      query: {
        buildingNo: item.buildingNo + ''
      }
    })
  }

  // 跳转至报修
  go2Repair(item: any) {
    this.$router.push({
      path: '/residents/people-list',
      query: {
        buildingNo: item.buildingNo + ''
      }
    })
  }

  // 跳转车辆
  go2Car(item: any) {
    this.$router.push({
      path: '/cars/vehicle-list',
      query: {
        houseInfo: item.buildingNo + ''
      }
    })
  }

  // 跳转车位
  go2CarPort(item: any) {
    this.$router.push({
      path: '/cars/carport-list',
      query: {
        houseNo: item.buildingNo + ''
      }
    })
  }

  // 查看图片
  viewImg(img: any) {
    this.imgUrl = img
  }

  // 关闭图片查看
  closeImg() {
    this.imgUrl = ''
  }

  // 返回
  goback() {
    this.$router.go(-1)
  }
}
